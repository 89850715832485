<template>
  <div>
    <v-dialog
      v-model="recalcularDialog"
      scrollable
      persistent
      max-width="500"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-cog-sync</v-icon>Recalcular
          <v-btn
            @click="recalcularDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b> Selecione o período para finalização:</b></p>
          <v-menu
            v-model="dateMenu1"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu1 = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
          <br />
          <p>
            Selecione um ou mais colaboradores. Deixe em branco para selecionar
            todos.
          </p>
          <v-autocomplete
            v-model="colaboradoresSelecionados"
            :disabled="!colaboradores || colaboradores.length === 0"
            :items="colaboradores"
            item-text="nome"
            item-value="id"
            placeholder="Todos colaboradores"
            dense
            multiple
            hide-details
            clearable
            prepend-icon="mdi-account"
            :search-input.sync="searchInputValue"
            @blur="searchInputValue = ''"
          ></v-autocomplete>
          <v-radio-group v-model="recalcularTodas" row>
            <v-radio label="Apenas folhas alteradas" :value="false"></v-radio>
            <v-radio label="Todas as folhas" :value="true"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="recalcularDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              doRecalcCliente(
                competenciaFilter.ini,
                competenciaFilter.fim,
                colaboradoresSelecionados,
                recalcularTodas
              )
            "
            >Executar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="finalizarDialog || desFinalizarDialog"
      scrollable
      max-width="600"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title>
          <template v-if="finalizarDialog">
            <v-icon x-large class="mr-3">mdi-archive-cog</v-icon> Finalizar Cálculo
          </template>
          <template v-else>
            <v-icon x-large class="mr-3">mdi-archive-sync</v-icon> Reabrir Cálculo
          </template>
          <v-btn
            @click="
              finalizarDialog = false;
              desFinalizarDialog = false;
            "
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <template v-if="finalizarDialog">
            <p>
              Alterações feitas nas rubricas, projetos ou timesheets não irão
              alterar o status da FOPAG após a finalização do cálculo.
            </p>
            <p><b> Selecione o período para finalização:</b></p>
          </template>
          <template v-if="desFinalizarDialog">
            <p><b>Solicitando abertura do cálculo para o cliente</b></p>
          </template>
          <v-menu
            v-model="dateMenu2"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu2 = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="
              finalizarDialog = false;
              desFinalizarDialog = false;
            "
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="doExecute()"
            >Executar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changeFolhaItem.dialog"
      scrollable
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-square-edit-outline</v-icon>Alterar
          Rubrica
          <v-btn
            @click="doExitChangeDialog()"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-row class="ml-n4 mr-0" v-if="changeFolhaItem.dialog">
            <kore-select
              class="pl-4"
              v-model="changeFolhaItem.value.valorAntigo"
              :label="changeFolhaItem.fields.valorAntigo"
              :editable="false"
              :colSize="6"
            ></kore-select>
            <kore-select
              class="pl-4"
              v-model="changeFolhaItem.value.correto"
              :label="changeFolhaItem.fields.correto"
              :opts="changeFolhaItem.collections"
              :editable="!changeFolhaItem.isSaving"
              :colSize="6"
            ></kore-select>
            <v-expand-transition>
              <kore-select
                class="pl-4"
                v-if="!changeFolhaItem.value.correto"
                v-model="changeFolhaItem.value.valor"
                :valid.sync="changeFolhaItem.fields.valor.valid"
                :label="changeFolhaItem.fields.valor"
                :editable="!changeFolhaItem.isSaving"
              ></kore-select>
            </v-expand-transition>
            <kore-select
              class="pl-4"
              v-model="changeFolhaItem.value.comentario"
              :valid.sync="changeFolhaItem.fields.comentario.valid"
              :label="changeFolhaItem.fields.comentario"
              :editable="!changeFolhaItem.isSaving"
            ></kore-select>
          </v-row>
          <v-alert
            v-if="changeFolhaItem.errorMessage"
            dense
            style="font-size: 13px"
            text
            type="error"
            >{{ changeFolhaItem.errorMessage }}</v-alert
          >
          <template
            v-if="
              changeFolhaItem.dialog &&
              changeFolhaItem.value.comentarios.length > 0
            "
          >
            <v-card-subtitle
              class="v-label-input py-0 px-2"
              style="height: 30px"
            >
              {{ changeFolhaItem.value.comentarios.length }} comentário{{
                changeFolhaItem.value.comentarios.length > 1 ? "s" : ""
              }}
              <v-btn
                class="ml-2"
                small
                icon
                @click="
                  changeFolhaItem.exibirComentarios =
                    !changeFolhaItem.exibirComentarios
                "
              >
                <v-icon v-if="changeFolhaItem.exibirComentarios"
                  >mdi-chat-alert</v-icon
                >
                <v-icon v-else>mdi-chat-alert-outline</v-icon>
              </v-btn>
            </v-card-subtitle>
            <v-expand-transition>
              <div v-if="changeFolhaItem.exibirComentarios">
                <template
                  v-for="(comentario, index) in changeFolhaItem.value
                    .comentarios"
                >
                  <v-divider
                    class="my-0 mx-0"
                    v-if="index > 0"
                    :key="'d' + index"
                    :inset="false"
                  ></v-divider>

                  <v-card-text
                    :key="'c' + index"
                    class="v-label-input py-2 px-4"
                  >
                    <span style="font-weight: 500">
                      {{ comentario.user.nome || comentario.user}} há
                      {{ comentario.criado_em | toFromNow }}
                    </span>
                    <br />
                    <span>{{ comentario.comentario }}</span>
                  </v-card-text>
                </template>
              </div>
            </v-expand-transition>
          </template>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="doExitChangeDialog()"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="doSaveFolhaItem()"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <kore-modal
      title="Ação não recomendada"
      :value.sync="exportSyncDialog"
      @confirm="exportSyncType === 'csv' ? handleExportCsv() : handleExportXlsx()"
    >
      <p><strong>Atenção!</strong> Esta opção de exportação síncrona será descontinuada pois dependendo do volume de dados a operação pode não ser concluída.</p>
      <p>Por favor, opte pela exportação assíncrona.</p>
    </kore-modal>

    <div id="main" :style="{ width: getWidth }">
      <div
        v-if="!clientId"
        style="
          font-size: 25px;
          font-weight: bold;
          line-height: calc(100vh - 200px);
        "
      >
        SELECIONE UM ITEM PARA VISUALIZAR OS RELATÓRIOS
      </div>
      <div v-else>
        <div
          class="page_title d-flex justify-space-beetween"
          style="margin-top: -20px; margin-bottom: 10px;"
        >
          <span class="page-title">
            {{ dispendio ? nomeFantasia : "Carregando" }}
          </span>
          <v-menu offset-y :disabled="!dispendio">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="table-v-action-button ml-3 pt-2"
                :disabled="!dispendio"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-download</v-icon>
                Opções de Exportação
              </div>
            </template>
            <v-list>
              <v-list-item>
                <async-export-button
                  anoBase
                  beneficio
                  type="dispendioRH"
                ></async-export-button>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="exportSyncType = 'csv'; exportSyncDialog = true"
                >
                  <v-icon>mdi-file-delimited-outline</v-icon>
                  Exportação síncrona CSV
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="exportSyncType = 'xlsx'; exportSyncDialog = true"
                >
                  <v-icon>mdi-file-excel-outline</v-icon>
                  Exportação síncrona XLSX
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="table-v-action-button ml-3 pt-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-cog-sync</v-icon>
                Opções de Cálculo
              </div>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="recalcularDialog = true"
                >
                  <v-icon>mdi-cog-sync</v-icon>Recalcular
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="finalizarDialog = true"
                >
                  <v-icon>mdi-archive-cog</v-icon>Finalizar Cálculo
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="userIsAdmin">
                <v-list-item-title
                  class="table-v-action-button"
                  @click="desFinalizarDialog = true"
                >
                  <v-icon>mdi-archive-sync</v-icon>Reabrir Cálculo
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <calculation-status-menu class="table-v-action-button ml-3 pt-2"></calculation-status-menu>
        </div>
        <div class="page_title d-flex justify-space-beetween">
          <year-select
            style="max-width: 130px"
            class="mr-3"
            @change="
              anoBase = $event;
              doLoad();
              setCompetenciaPeloAno();
            "
          ></year-select>
          <servico-select
            style="max-width: 260px"
            class="mr-3"
            @change="servico = $event; doLoad();"
          ></servico-select>
          <v-autocomplete
            v-if="!$route.query.colaboradorId"
            v-model="funcionarioId"
            :items="colaboradores"
            item-value="id"
            item-text="nome"
            prefix="Colaborador:"
            placeholder="todos"
            prepend-icon="mdi-account-box-outline"
            class="mr-3"
            style="max-width: 510px"
            dense
            hide-details
            clearable
            @change="doLoad()"
          ></v-autocomplete>
          <pagination
            v-if="totalPages > 0"
            v-model="currentPage"
            :length="totalPages"
            style="margin-top: -9px; max-width: 280px"

          ></pagination>
        </div>
        <template v-if="!dispendio">
          <div
            v-if="clientId && !dispendio"
            style="
              font-size: 25px;
              font-weight: bold;
              line-height: calc(100vh - 200px);
            "
          >
            {{ loadingMessage() }}
          </div>
        </template>
        <template v-else-if="dispendio.rows && dispendio.rows.length === 0">
          <div
            style="
              font-size: 25px;
              font-weight: bold;
              line-height: calc(100vh - 200px);
            "
          >
            NENHUM DADO ENCONTRADO PARA O PERÍODO SELECIONADO.
          </div>
        </template>
        <template v-else>
          <div class="content">
            <div
              v-for="(b, bk) in dispendio.rows"
              :key="'box' + bk"
              class="box"
            >
              <div>
                <div class="table">
                  <!-- COLUNA A ESQUERDA COM DESCRIÇÕES -->
                  <div
                    class="tl"
                    style="
                      width: 300px;
                      position: sticky;
                      left: -20px;
                      z-index: 1;
                    "
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="title-dispendio py-0"
                          style="
                            position: sticky;
                            top: -20px;
                            z-index: 1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 50px;
                          "
                        >
                          <span style="display: block">{{
                            b.colaborador.nome
                          }}</span>
                          <span
                            class="mt-n3"
                            style="display: block; font-size: 10px"
                            >{{ getColaboradorInfo(b.colaborador) }}</span
                          >
                        </div>
                      </template>
                      <span>Nome: {{ b.colaborador.nome }}</span>
                    </v-tooltip>

                    <v-tooltip right v-for="(t, k) in dispCima" :key="'xd' + k">
                      <template v-slot:activator="{ on }">
                        <div
                          class="item text-truncate"
                          :style="{
                            color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                            background: bgl(bk, -1, `a${('00' + k).slice(-3)}`),
                          }"
                        >
                          <span v-on="on">{{ t.codigo ? `#${t.codigo} — ${t.nome.nome}` : t.nome.nome }}</span>
                        </div>
                      </template>
                      {{ t.nome.nome }}
                    </v-tooltip>

                    <div class="item itemb">TOTAL DE VENCIMENTOS</div>
                    <v-tooltip right v-for="(t, k) in dispEncargos" :key="'xdc' + k">
                      <template v-slot:activator="{ on }">
                        <div
                          class="item text-truncate"
                          :style="{
                            color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                            background: bgl(bk, -1, `a${('00' + k).slice(-3)}`),
                          }"
                        >
                          <span v-on="on">{{ t.codigo ? `#${t.codigo} — ${t.nome.nome}` : t.nome.nome }}</span>
                        </div>
                      </template>
                      {{ t.nome.nome }}
                    </v-tooltip>

                    <div class="item itemb">TOTAL CUSTO FUNCIONÁRIO</div>
                    <v-tooltip right v-for="(t, k) in dispHoras" :key="'xdh' + k">
                      <template v-slot:activator="{ on }">
                        <div
                          class="item text-truncate"
                          :style="{
                            color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                            background: bgl(bk, -1, `a${('00' + k).slice(-3)}`),
                          }"
                        >
                          <span v-on="on">{{ t.codigo ? `#${t.codigo} — ${t.nome.nome}` : t.nome.nome }}</span>
                        </div>
                      </template>
                      {{ t.nome.nome }}
                    </v-tooltip>

                    <div class="item itemb">Valor dedicado a P&D</div>
                  </div>
                  <!-- COLUNA A ESQUERDA COM DESCRIÇÕES: FIM -->
                  <div
                    class="tl tlc"
                    v-for="(c, k) in b.competencias"
                    :key="'lf-' + k"
                    style="width: 100px"
                  >
                    <!-- CABEÇALHO -->

                    <v-tooltip
                      bottom
                      :disabled="!recalculoStatus[getServiceStatus(c)]"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="title-dispendio"
                          :style="{
                            position: 'sticky',
                            cursor: 'pointer',
                            'z-index': 0,
                            top: '-20px',
                            color: recalculoColors[getServiceStatus(c)],
                          }"
                        >
                          {{ c.competencia | toMonth }}
                        </div>
                      </template>
                      <span>{{ recalculoStatus[getServiceStatus(c)] }}</span>
                    </v-tooltip>

                    <!-- CABEÇALHO: FIM -->
                    <template v-for="(t, l) in dispCima">
                      <div
                        v-for="rubrica in [
                          getRubrica(c.rubricasResolved, t.nome.id, l),
                        ]"
                        :key="'xd' + rubrica.id"
                        class="item value"
                        :style="{
                          'z-index': -2,
                          color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                          background: bgl(bk, k, `a${('00' + l).slice(-3)}`),
                        }"
                        @mouseover="actOver(k, `a${('00' + l).slice(-3)}`, bk)"
                        :class="{
                          desvio: rubrica.hasDesvio,
                          clicavel:
                            t.nome.id != -3 &&
                            t.nome.id != -2 &&
                            c.status !== 2,
                        }"
                        @click="
                          clickRubrica(
                            rubrica,
                            b.colaborador.id,
                            t,
                            c.status !== 2
                          )
                        "
                      >
                        <v-badge
                          v-if="showBadgeComentarios(rubrica.comentarios)"
                          color="success"
                          dot
                          left
                          >{{ rubrica.valorCalculado | toCurrency }}</v-badge
                        >
                        <template v-else>{{
                          rubrica.valorCalculado | toCurrency
                        }}</template>
                      </div>
                    </template>

                    <div class="item itemb value">
                      <!-- TOTAL CUSTOS -->
                      {{ c.custo | toCurrency }}
                    </div>
                    <template v-for="(t, l) in dispEncargos">
                      <div
                        v-for="rubrica in [
                          getRubrica(c.rubricasResolved, t.nome.id, l),
                        ]"
                        class="item value"
                        :key="'xdb' + rubrica.id"
                        :style="{
                          color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                          background: bgl(bk, k, `b${('00' + l).slice(-3)}`),
                        }"
                        @mouseover="actOver(k, `b${('00' + l).slice(-3)}`, bk)"
                        :class="{
                          desvio: rubrica.hasDesvio,
                          clicavel:
                            t.nome.id != -3 &&
                            t.nome.id != -2 &&
                            c.status !== 2,
                        }"
                        @click="
                          clickRubrica(
                            rubrica,
                            b.colaborador.id,
                            t,
                            c.status !== 2
                          )
                        "
                      >
                        <v-badge
                          v-if="rubrica.comentario && rubrica.comentario != ''"
                          color="success"
                          dot
                          left
                          >{{ rubrica.valorCalculado | toCurrency }}</v-badge
                        >
                        <template v-else>{{
                          rubrica.valorCalculado | toCurrency
                        }}</template>
                      </div>
                    </template>

                    <div class="item itemb value">
                      <!-- TOTAL CUSTOS ENCARGOS -->
                      {{ c.custoEncargos | toCurrency }}
                    </div>

                    <template v-for="(t, l) in dispHoras">
                      <div
                        v-for="rubrica in [
                          getRubrica(c.rubricasResolved, t.nome.id, l),
                        ]"
                        class="item value"
                        :key="'xdc' + rubrica.id"
                        :style="{
                          color: (t.nome.cons & 0x100) === 0x100 ? 'red' : '',
                          background: bgl(bk, k, `c${('00' + l).slice(-3)}`),
                        }"
                        @mouseover="actOver(k, `c${('00' + l).slice(-3)}`, bk)"
                        :class="{
                          desvio: rubrica.hasDesvio,
                          clicavel:
                            t.nome.id != -3 &&
                            t.nome.id != -2 &&
                            c.status !== 2,
                        }"
                        @click="
                          clickRubrica(
                            rubrica,
                            b.colaborador.id,
                            t,
                            c.status !== 2
                          )
                        "
                      >
                        <v-icon
                          v-if="rubrica.alert && t.canAlert"
                          color="#bc9010"
                        >
                          mdi-alert-outline
                        </v-icon>
                        <v-badge
                          v-if="rubrica.comentario && rubrica.comentario != ''"
                          color="success"
                          dot
                          left
                        >
                          <template v-if="t.isTime">{{
                            rubrica.valorCalculado | toDoubleComplete
                          }}</template>
                          <template v-else>{{
                            rubrica.valorCalculado | toCurrency
                          }}</template>
                        </v-badge>
                        <template v-else>
                          <template v-if="t.isTime">{{
                            rubrica.valorCalculado | toDoubleComplete
                          }}</template>
                          <template v-else>{{
                            rubrica.valorCalculado | toCurrency
                          }}</template>
                        </template>
                      </div>
                    </template>
                    <div class="item itemb value">
                      <!-- TOTAL CUSTOS P&D -->
                      {{ c.custoPD | toCurrency }}
                    </div>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>

            <div
              v-if="paginate"
              style="
                font-size: 25px;
                font-weight: bold;
                padding: 10px;
                text-align: center;
              "
            >
              CARREGANDO DADOS, POR FAVOR AGUARDE...
            </div>
          </div>
          <pagination
            v-if="totalPages > 0"
            v-model="currentPage"
            :length="totalPages"
            style="margin-top: -35px"
          ></pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
import { BeneficiosEnum } from '@/core/enums/beneficios';

export default {
  props: ["title", "labels", "load", "folders", "sub"],
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "calculation-status-menu": () => import("@/components/calculation-status-menu.vue"),
    "kore-modal": () => import("@/components/kore-modal.vue"),
    "kore-select": () => import("@/components/input-v.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
    "year-select": () => import("@/components/year-select.vue"),
    pagination: () => import("@/components/pagination.vue")
  },
  watch: {
    currentPage: function () {
      this.doLoad();
    },
    clientId: function () {
      this.funcionarioId = null;
      this.dispendio = null;
      this.listaColaboradores();
    }
  },
  computed: {
    ...mapGetters(['clientId', 'selectedClient', 'userIsAdmin']),
    anosBase: function () {
      var anoAtual = new Date().getFullYear();
      return [anoAtual, anoAtual - 1];
    },
    dispCima: function () {
      return _.filter(this.dispendio?.template, (a) => {
        var f = a.nome.cons;
        return (f & 0x01) === 0x01 || (f & 0x10) === 0x10;
      });
    },
    dispEncargos: function () {
      return [
        { nome: { id: -2, nome: "INSS Folha" } },
        { nome: { id: -3, nome: "FGTS Folha" } },
      ].concat(
        _.filter(this.dispendio?.template, (a) => {
          var f = a.nome.cons;
          return (f & 0x02) === 0x02 || (f & 0x20) === 0x20;
        })
      );
    },
    dispHoras: function () {
      return [
        { nome: { id: -6, nome: "Total de Horas Trabalhadas" }, isTime: true },
        { nome: { id: -4, nome: "Custo por Hora" } },
        {
          nome: { id: -5, nome: "Horas dedicadas a P&D" },
          isTime: true,
          canAlert: true,
        },
      ];
    },
    getWidth: function () {
      if (this.selected && this.dispendio != false) {
        return 1230 + 300 + "px";
      } else {
        return "auto";
      }
    },
    totalPages: function () {
      return Math.ceil(this.dispendio ? this.dispendio.total / 2 : 0);
    },
    competenciaFilter: function () {
      var mIni = moment(this.competenciaRecalculo[0]);
      var mFim = moment(this.competenciaRecalculo[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competenciaRecalculo[1]);
        mFim = moment(this.competenciaRecalculo[0]);
      }
      return { ini: mIni.format("YYYY-MM"), fim: mFim.format("YYYY-MM") };
    },
    nomeFantasia: function () {
      return this.selectedClient?.fantasia;
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    resourceStatusFopag: function () {
      return this.apiResource(`/v1/rh/statusfopag`);
    },

  },
  created: function () {
    const [ini, fim] = this.getFilters("monthly") || [
      moment().startOf("quarter").format("YYYY-MM"),
      moment().endOf("quarter").format("YYYY-MM"),
    ];
    this.competenciaRecalculo = [ini, fim];
    this.setCompetenciaPeloAno();
    this.listaColaboradores();
    this.resourceStatusFopag.get().then((list) => {
      this.recalculoStatus = list.reduce(
        (prev, { codigo, legenda }) => ({ ...prev, [codigo]: legenda }),
        {}
      );
      this.recalculoColors = list.reduce(
        (prev, { codigo, cor }) => ({ ...prev, [codigo]: cor }),
        {}
      );
    });
  },
  methods: {
    doLoad: function () {
      if (!this.anoBase || !this.servico) {
        return;
      }

      if (this.clientId) {
        this.selected = true;
        this.executeLoad();
      }
    },
    executeLoad: async function (isRelatorio = false, isCsv = false) {
      const that = this;
      const query = `anoBase=${this.anoBase}&trabalho=${this.servico}&page=${isRelatorio ? -1 : this.currentPage}`;
      const hasId = this.$route.query.colaboradorId || this.funcionarioId;
      const resource = this.apiResource(`/v1/rh/clientes/${this.clientId}/dispendio/novo${hasId ? `/${hasId}` : ''}`);
      resource.get({query: query}).then((dados) => {
        that.loaded = true;
        const hasError = dados.data;
        if (!hasError) {
          that.processTemplates(dados);
          that.processRubricas(dados, dados.rows);
          this.dispendio = dados;

          if(isRelatorio){
            isCsv ? this.exportCsv(this.dispendioArray(dados)) : this.exportXlsx(this.dispendioArray(dados));
          }
          return dados;
        }
        return this.notificaErro(hasError.message);
      });
    },
    notificaErro(mensagem) {
      this.loadingMessage(mensagem);
      this.$notify({
        title: "Atenção",
        group: 'geral',
        message: mensagem,
        type: "error",
        duration: 5000,
      });
    },
    loadingMessage: function (mensagem) {
      return mensagem || "Carregando dados, aguarde...";
    },
    dispendioArray: function (dispendio, needFormat) {
      var ar = [];
      dispendio?.rows.forEach((col) => {
        var cabecalho = ["Colaborador", "Dedicação", "Descrição"];
        col.competencias.forEach((c) => {
          cabecalho.push(this.$options.filters.toMonth(c.competencia));
        });
        ar.push(cabecalho);

        this.dispCima.forEach((t) => {
          var dedicacao =
            col.colaborador.dedicacao == 1 ? "Exclusivo" : "Parcial";
          var row = [];
          row.push(col.colaborador.nome);
          row.push(dedicacao);
          row.push(t.nome.nome);
          col.competencias.forEach((c) => {
            const valorCalc = this.validaAtributoValorCalc(c.rubricasResolved, t.nome.id);
            row.push(valorCalc);
          });
          ar.push(row);
        });
        var dedicacao =
          col.colaborador.dedicacao == 1 ? "Exclusivo" : "Parcial";
        var totalVenc = [
          col.colaborador.nome,
          dedicacao,
          "TOTAL DE VENCIMENTOS",
        ];
        col.competencias.forEach((c) => {
          let total = needFormat
            ? this.$options.filters.toCurrency(c.custo)
            : c.custo;

          if (typeof total === "string") {
            total = parseFloat(total);
          }

          totalVenc.push(total);
        });
        ar.push(totalVenc);

        this.dispEncargos.forEach((t) => {
          var dedicacao =
            col.colaborador.dedicacao == 1 ? "Exclusivo" : "Parcial";
          var row = [];
          row.push(col.colaborador.nome);
          row.push(dedicacao);
          row.push(t.nome.nome);
          col.competencias.forEach((c) => {
            let valorCalc = this.validaAtributoValorCalc(c.rubricasResolved, t.nome.id);
            valorCalc = needFormat ? this.$options.filters.toCurrency(valorCalc) : valorCalc;
            row.push(valorCalc);
          });
          ar.push(row);
        });

        var totalEnc = [
          col.colaborador.nome,
          dedicacao,
          "TOTAL CUSTO FUNCIONÁRIO",
        ];
        col.competencias.forEach((c) => {
          let total = needFormat
            ? this.$options.filters.toCurrency(c.custoEncargos)
            : c.custoEncargos;

          if (typeof total === "string") {
            total = parseFloat(total);
          }

          totalEnc.push(total);
        });
        ar.push(totalEnc);

        this.dispHoras.forEach((t) => {
          var format = t.isTime
            ? this.$options.filters.toCurrencyValue
            : this.$options.filters.toCurrency;
          var dedicacao =
            col.colaborador.dedicacao == 1 ? "Exclusivo" : "Parcial";
          var row = [];
          row.push(col.colaborador.nome);
          row.push(dedicacao);
          row.push(t.nome.nome);
          col.competencias.forEach((c) => {
            let valorCalc = this.validaAtributoValorCalc(c.rubricasResolved, t.nome.id);
            valorCalc = needFormat ? format(valorCalc) : valorCalc;
            row.push(valorCalc);
          });
          ar.push(row);
        });

        var totalHoras = [
          col.colaborador.nome,
          dedicacao,
          "Valor Dedicado a P&D",
        ];
        col.competencias.forEach((c) => {
          let total = c.custoPD;

          if (typeof total === "string") {
            total = parseFloat(total);
          }

          totalHoras.push(_.isNaN(total) ? 0 : total);
        });
        ar.push(totalHoras);
      });
      return ar;
    },
    validaAtributoValorCalc(rubrica, idColaborador) {
      return (rubrica != null && idColaborador != null && rubrica[idColaborador]?.valorCalculado) ? rubrica[idColaborador].valorCalculado : "";
    },
    handleExportCsv: function () {
      this.executeLoad(true, true);
    },
    handleExportXlsx: function () {
      this.executeLoad(true, false);
    },
    processTemplates: function (dispendio) {
      dispendio.templatesResolved = {};
      _.forEach(dispendio?.template, (template) => {
        template.dispCima =
          (template.nome.cons & 0x01) === 0x01 ||
          (template.nome.cons & 0x10) === 0x10;
        template.dispBaixo =
          (template.nome.cons & 0x02) === 0x02 ||
          (template.nome.cons & 0x20) === 0x20;
        template.negative = (template.nome.cons & 0x100) === 0x100;
        dispendio.templatesResolved[template.nome.id] = template;
      });
    },
    processRubricas: function (dispendio, colaboradores) {
      _.forEach(colaboradores, (colaborador) => {
        colaborador.mediaRubricas = {};
        _.forEach(colaborador.competencias, (competencia) => {
          competencia.rubricasResolved =
            competencia.rubricas.length > 0 ? {} : false;
          _.forEach(competencia.rubricas, (rubrica) => {
            if (!colaborador.mediaRubricas[rubrica.rubID]) {
              colaborador.mediaRubricas[rubrica.rubID] = {
                count: 0,
                total: 0,
                values: [],
              };
            }
            var tRubrica = colaborador.mediaRubricas[rubrica.rubID];
            tRubrica.count++;

            var template = dispendio.templatesResolved[rubrica.rubID];
            rubrica.valorCalculado =
              rubrica.valor * (template && template.negative ? -1 : 1);
            tRubrica.total += rubrica.valorCalculado;
            tRubrica.values.push(rubrica.valorCalculado);
            rubrica.alert = parseInt(rubrica.flagAlerts) === 1;
            competencia.rubricasResolved[rubrica.rubID] = rubrica;
          });

          competencia.custo = this.calcCusto(competencia.rubricas);
          competencia.custoPD = this.calcCustoPD(competencia.rubricas);
          competencia.custoEncargos = this.calcCustoEncargos(competencia.rubricas);

          if(this.servico == 4) {
            competencia.rubricas.map((a) => {
              if(a.rubID !== -9) return;
              competencia.custoPD = a.valor
            })
          }
        });

        _.forEach(colaborador.mediaRubricas, (tRubrica) => {
          tRubrica.media = tRubrica.total / tRubrica.count;
          tRubrica.desvioPadrao = this.getStandardDeviation(tRubrica.values);
          tRubrica.coeficienteVariacao =
            (tRubrica.desvioPadrao / tRubrica.media) * 100;
          tRubrica.dispersao =
            tRubrica.coeficienteVariacao <= 15
              ? "baixa"
              : tRubrica.coeficienteVariacao < 30
              ? "media"
              : "alta";
        });

        _.forEach(colaborador.competencias, (competencia) => {
          _.forEach(competencia.rubricasResolved, (rubrica) => {
            rubrica.hasDesvio = this.hasDesvio(
              rubrica.valorCalculado,
              colaborador.mediaRubricas[rubrica.rubID].media,
              colaborador.mediaRubricas[rubrica.rubID].desvioPadrao
            );
            rubrica.comentarios = _.sortBy(
              JSON.parse(
                !rubrica.comentario || rubrica.comentario == ""
                  ? "[]"
                  : rubrica.comentario
              ),
              "criado_em"
            ).reverse();
          });
        });
      });
    },
    getRubrica: function (rubricas, rubID, idx) {
      return rubricas && rubricas[rubID]
        ? rubricas[rubID]
        : { id: idx, valorCalculado: "--" };
    },
    hasDesvio: function (valor, media, desvio) {
      var mediaAlta = media + desvio;
      var mediaBaixa = media - desvio;

      return valor > mediaAlta || valor < mediaBaixa;
    },
    getStandardDeviation: function (array) {
      const n = array.length;
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    },
    bgl: function (t, c, l) {
      if (this.focus.t === t) {
        if (this.focus.c === c && this.focus.l === l) {
          return "#93a8c3";
        } else if (this.focus.c === c && this.focus.l > l) {
          return "#cfe7fd";
        } else if (this.focus.l === l && this.focus.c > c) {
          return "#cfe7fd";
        }
      }

      return "";
    },
    actOver: function (c, l, t = 1) {
      this.focus.c = c;
      this.focus.l = l;
      this.focus.t = t;
    },
    doRecalc: function (cid, fid) {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "warn",
        title: "Solicitando recalculo para o colaborador",
        text: "A competência será recalculada em breve...",
      });
      // TODO Adicionar data de último cálculo

      this.korePost(
        "/v1/rh/clientes/" +
          this.clientId +
          "/dispendio/" +
          cid +
          "/recalc",
        { competencia: fid, trabalho: this.servico },
        (a) => {
          this.paginate = false;
          if (a.error) {
            this.showAlert("Alerta!", a.error, () => {});
          } else {
            window.location.reload();
          }
        }
      );
    },
    doExecute: function () {
      if (this.finalizarDialog) {
        this.doFinalizarCliente(
          this.competenciaFilter.ini,
          this.competenciaFilter.fim
        );
      } else if (this.desFinalizarDialog) {
        this.doDesFinalizarCliente(
          this.competenciaFilter.ini,
          this.competenciaFilter.fim
        );
      }
      this.finalizarDialog = false;
      this.desFinalizarDialog = false;
    },
    doDesFinalizarCliente: function (competenciaIni, competenciaFim) {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "warn",
        title: "Solicitando reabertura do cálculo para o cliente",
        text: "O status da FOPAG será atualizado em breve...",
      });

      this.korePost(`/v1/clientes/${this.clientId}/abrir/fopag`,
        {
          competenciaIni: competenciaIni,
          competenciaFim: competenciaFim,
          trabalho: this.servico,
        },
        (a) => {
          this.paginate = false;
          if (a.error) {
            this.showAlert("Alerta!", a.error, () => {});
          } else {
            this.doLoad();
          }
        }
      );
    },
    doFinalizarCliente: function (competenciaIni, competenciaFim) {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "warn",
        title: "Solicitando finalização do cálculo para o cliente",
        text: "O status da FOPAG será atualizado em breve...",
      });

      this.korePost(`/v1/clientes/${this.clientId}/fechar/fopag`,
        {
          competenciaIni: competenciaIni,
          competenciaFim: competenciaFim,
          trabalho: this.servico,
        },
        (a) => {
          this.paginate = false;
          if (a.error) {
            this.showAlert("Alerta!", a.error, () => {});
          } else {
            this.doLoad();
          }
        }
      );
    },
    doRecalcCliente: function (
      competenciaIni,
      competenciaFim,
      colaboradores,
      todasFolhas
    ) {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "warn",
        title: "Solicitando recalculo para o cliente",
        text: "A competência será recalculada em breve...",
      });

      this.korePost(
        "/v1/rh/clientes/" + this.clientId + "/dispendio/recalc", // TODO fica, vai servir para empresa toda ou só alguns colaboradores
        {
          competenciaIni: competenciaIni,
          competenciaFim: competenciaFim,
          trabalho: this.servico,
          colaboradores,
          todasFolhas,
        },
        (a) => {
          this.paginate = false;
          if (a.error) {
            this.showAlert("Alerta!", a.error, () => {});
          } else {
            window.location.reload();
          }
        }
      );
    },
    calcCusto: function (r) {
      var tmp = _.sumBy(r, (a) => {
        if (
          ((a.flag & 0x01) === 0x01 || (a.flag & 0x10) === 0x10) &&
          a.rubID > 0
        ) {
          return a.valor * ((a.flag & 0x100) === 0x100 ? -1 : 1);
        }
        return 0;
      });
      return tmp.toFixed(2);
    },
    calcCustoEncargos: function (r) {
      var tmp = _.sumBy(r, (a) => {
        if (
          ((a.flag & 0x10) === 0x10 ||
            (a.flag & 0x01) === 0x01 ||
            (a.flag & 0x02) === 0x02 ||
            (a.flag & 0x20) === 0x20) &&
          a.rubID > 0
        ) {
          return (a.flag & 0x100) === 0x100 ? -a.valor : a.valor;
        } else if (a.rubID === -2 || a.rubID === -3) {
          return a.valor;
        }

        return 0;
      });

      return tmp.toFixed(2);
    },
    calcCustoPD: function (r) {
      var horas = _.find(r, { rubID: -4 });
      var horasPD = _.find(r, { rubID: -5 });

      if (horas && horasPD) {
        return (horas.valor * horasPD.valor).toFixed(2);
      } else {
        return "--";
      }
    },
    doExitChangeDialog: function () {
      this.changeFolhaItem.dialog = false;
      this.changeFolhaItem.exibirComentarios = false;
    },
    clickRubrica: function (rubrica, colaboradorId, template, competenciaOK) {
      if (template.nome.id != -3 && template.nome.id != -2 && competenciaOK) {
        this.changeFolhaItem.value.correto = true;
        this.changeFolhaItem.value.valorAntigo = rubrica.valor;
        this.changeFolhaItem.value.valor = rubrica.valor;
        this.changeFolhaItem.value.itemId = rubrica.itemId;
        this.changeFolhaItem.value.fopagId = rubrica.fopagId;
        this.changeFolhaItem.value.colaboradorId = colaboradorId;
        this.changeFolhaItem.value.template = template;
        this.changeFolhaItem.value.comentario = "";

        this.changeFolhaItem.fields.valor.type = template.isTime
          ? this.$fieldTypes.MONEY2
          : this.$fieldTypes.MONEY;
        this.changeFolhaItem.fields.valorAntigo.type = template.isTime
          ? this.$fieldTypes.MONEY2
          : this.$fieldTypes.MONEY;
        this.changeFolhaItem.value.comentarios = rubrica.comentarios;

        this.changeFolhaItem.dialog = true;
      }
    },
    doSaveFolhaItem: function () {
      var resource = this.apiResource(
        `/v1/rh/${this.getClient().clientId}/colaboradores/${
          this.changeFolhaItem.value.colaboradorId
        }/fopag/${this.changeFolhaItem.value.fopagId}/itens`
      );

      this.formataFolhaItem();
      this.changeFolhaItem.value.comentarios.push({
        criado_em: new Date(),
        comentario: this.changeFolhaItem.value.comentario,
        user: this.getUser().nome,
      });
      var fopagItem = {
        codigo: this.changeFolhaItem.value.template.codigo,
        //descricao: this.changeFolhaItem.value.template.nome.nome,
        template: this.changeFolhaItem.value.template.nome.id,
        valor:  this.changeFolhaItem.value.valor,
        comentario: JSON.stringify(this.changeFolhaItem.value.comentarios)
      };
      const itemID = this.changeFolhaItem.value.itemId;
      resource.save(fopagItem, itemID).then((response) => {
          if (response.error) {
            this.changeFolhaItem.errorMessage = response.error;
          } else {
            this.doExitChangeDialog();
            setTimeout(this.doLoad, 1000);
          }
      });
    },
    formataFolhaItem: function() {
      var format = this.changeFolhaItem.value.template.isTime
        ? Vue.filter("toCurrencyValue")
        : Vue.filter("toCurrency");

      if (this.changeFolhaItem.value.correto) {
        this.changeFolhaItem.value.valor =
          this.changeFolhaItem.value.valorAntigo;
        this.changeFolhaItem.value.comentario = `Conferido. ${this.changeFolhaItem.value.comentario}`;
      } else {
        this.changeFolhaItem.value.comentario = `${format(
          this.changeFolhaItem.value.valorAntigo
        )} > ${format(this.changeFolhaItem.value.valor)}: ${
          this.changeFolhaItem.value.comentario
        }`;
      }
    },
    showBadgeComentarios: function (comentarios) {
      return comentarios
        ? comentarios.filter((c) => c.user != "Teros").length > 0
        : false;
    },
    getColaboradorInfo: function (colaborador) {
      if (this.servico === BeneficiosEnum.CAPTACAO) {
        return '';
      }

      return (
        "Dedicação: " +
        this.servicoColaboradorInfo[this.servico][colaborador.dedicacao]
      );
    },
    getServiceStatus: function (colaborador) {
      switch (this.servico) {
        case BeneficiosEnum.CAPTACAO:
          return colaborador.statusCAP;
        case BeneficiosEnum.LEI_DE_INFORMATICA:
          return colaborador.statusLI;
        default:
          return colaborador.status;
      }
    },
    setCompetenciaPeloAno: function () {
      if (this.competenciaRecalculo.length > 1 && this.anoBase) {
        const [oldIni, oldFim] = this.competenciaRecalculo;
        const anoBase = parseInt(this.anoBase, 10);
        const ini = moment(oldIni, 'YYYY-MM').set("year", anoBase).format("YYYY-MM");
        const fim = moment(oldFim, 'YYYY-MM').set("year", anoBase).format("YYYY-MM");
        this.competenciaRecalculo = [ini, fim];
      }
    },
    setColaboradoresSelecionadosInicial: function (id) {
      if (id && !this.colaboradoresSelecionados.includes(id)) {
        this.colaboradoresSelecionados.push(parseInt(id, 10));
      }
    },
    dialogKeyDown: function (keyEvent) {
      const { code } = keyEvent;
      if (code === "Escape") {
        this.recalcularDialog = false;
        this.finalizarDialog = false;
        this.desFinalizarDialog = false;
      }
    },
    listaColaboradores: function() {
      this.resourceColaboradores.get().then((response) => {
        this.colaboradores = response.colaboradores
          .map((col) => ({ ...col, nome: `${col.matricula || `Sem matrícula`} — ${col.nome}` }));

        if (this.$route.query.colaboradorId) {
          this.setColaboradoresSelecionadosInicial(
            this.$route.query.colaboradorId
          );
        }
    });
    }
  },
  data: function () {
    return {
      searchInputValue: "",
      currentPage: 1,
      exportSyncDialog: false,
      exportSyncType: 'csv',
      anoBase: null,
      servico: null,
      funcionarioId: null,
      servicoColaboradorInfo: {
        1: { 0: "Nenhum", 1: "Exclusivo", 2: "Parcial" },
        2: { 0: "Nenhum", 1: "Direto", 2: "Indireto" },
      },
      recalcularDialog: false,
      finalizarDialog: false,
      desFinalizarDialog: false,
      dateMenu1: false,
      dateMenu2: false,
      competenciaRecalculo: [],
      recalculoStatus: {},
      recalculoColors: {},
      changeFolhaItem: {
        isSaving: false,
        dialog: false,
        exibirComentarios: false,
        value: {
          correto: true,
          valorAntigo: "",
          valor: "",
          itemId: "",
          comentario: "",
          template: false,
          comentarios: [
            {
              criado_em: new Date(),
              comentario: "this. changeF lhaIt em.valu e.co entario",
              user: this.getUser(),
            },
            {
              criado_em: new Date(),
              comentario: "this. changeF lhaIt em.valu e.co entario",
              user: this.getUser(),
            },
            {
              criado_em: new Date(),
              comentario: "this. changeF lhaIt em.valu e.co entario",
              user: this.getUser(),
            },
            {
              criado_em: new Date(),
              comentario: "this. changeF lhaIt em.valu e.co entario",
              user: this.getUser(),
            },
          ],
        },
        errorMessage: false,
        fields: {
          valorAntigo: {
            key: "valorAntigo",
            name: "Valor",
            align: 1,
            type: this.$fieldTypes.MONEY,
            nowrap: true,
            colSize: 6,
          },
          correto: {
            key: "correto",
            name: "",
            align: 1,
            type: this.$fieldTypes.RADIO,
            rel: { to: "simNao", key: "id", name: "nome" },
            colSize: 6,
          },
          valor: {
            key: "valor",
            name: "Novo Valor",
            align: 1,
            type: this.$fieldTypes.MONEY,
            nowrap: true,
            colSize: 6,
          },
          comentario: {
            key: "comentario",
            name: "Comentário",
            align: 1,
            type: this.$fieldTypes.TEXTAREA,
          },
        },
        collections: {
          simNao: [
            {
              id: true,
              nome: "Correto",
            },
            {
              id: false,
              nome: "Incorreto",
            },
          ],
        },
      },
      focus: {
        t: false,
        c: 0,
        l: 0,
      },
      paginate: false, // TODO eu acho que essa variavel é sempre falsa e por tanto ínutil.
      loaded: false,
      rows: [],
      selected: false,
      dispendio: false,
      colaboradores: [],
      colaboradoresSelecionados: [],
      recalcularTodas: false,
    };
  },
};
</script>

<style scoped lang="scss">
.page_title {
  padding-left: 10px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #0f131e;
}

.desvio {
  border: 1px solid red !important;
}

.clicavel {
  cursor: pointer;
}

#left {
  float: left;
  width: 300px;
  border-right: silver 1px solid;
  height: calc(100vh - 170px);
  background: white;
}

.heading {
  background: white;
  border-bottom: silver 1px solid;
  height: 40px;
}

.content {
  text-align: left;
  box-sizing: border-box;

  ul {
    list-style: none;

    li {
      a {
        color: #1f2243;
        text-decoration: none;
        display: block;
        padding: 5px;
        padding-left: 20px;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          background: rgb(223, 224, 226);
        }
      }

      ul {
        li {
          a {
            padding-left: 40px;
          }

          ul {
            li {
              a {
                padding-left: 60px;
              }
            }
          }
        }
      }
    }
  }

  .box {
    margin-bottom: 40px;
    border-radius: 7px;
    border: rgb(224, 224, 224) 1px solid;
    /* overflow: hidden; */
    background: white;
  }

  .title-dispendio {
    background: rgb(159, 172, 196);
    padding: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px !important;
    letter-spacing: 0.01em;
    color: rgb(36, 36, 36);
  }

  .table {
    margin-bottom: 0px !important;
    .tl {
      float: left;
      border-right: rgb(224, 224, 224) 1px solid;
      background: white;
      .value {
        text-align: end;
        padding-right: 8px;
      }
      .item {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #212529;
        margin: 0px;
        padding-left: 10px;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        height: 30px;
        border-bottom: silver 1px dotted;

        :first {
          width: calc(100% - 10px);
        }
      }

      .itemb {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 30px !important;
        letter-spacing: 0.01em;
        color: #154677;
        opacity: 1;
        user-select: none;
        height: 30px !important;
        background: rgb(195, 203, 217);
      }
    }

    .tlc {
      text-align: center;
    }

    .th {
      background-color: rgb(235, 237, 239);
      border-bottom: rgb(224, 224, 224) 1px solid;
      .td {
        float: left;
        width: calc(10% - 10px);
        font-size: 14px;
        font-weight: bold;
        border-right: rgb(224, 224, 224) 1px solid;
        box-sizing: border-box;
      }

      &:after {
        content: " ";
        display: table;
        clear: both;
      }
    }

    .tr {
      background: white;
      border-bottom: rgb(224, 224, 224) 1px solid;
      .td {
        float: left;
        width: 10%;
        font-size: 14px;
        border-right: rgb(224, 224, 224) 1px solid;
        box-sizing: border-box;
      }

      &:after {
        content: " ";
        display: table;
        clear: both;
      }

      &:hover {
        background: #f2f9ff !important;
        .td {
          color: black !important;
        }
      }
    }
  }
}
</style>
